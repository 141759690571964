<script setup>
import { useLoganStore } from '~/stores/logan';
const LoganStore = useLoganStore();
import { Button } from '@laam/ui/base';

const { storeType } = useGlobalStore();
</script>

<template>
	<div
		class="lg:col-span-3 lg:flex lg:border lg:p-7xl lg:rounded-xl h-fit gap-xl flex-col hidden overflow-hidden"
	>
		<NuxtLink to="/profile" class="block">
			<Button size="lg" class="justify-start w-full" variant="tertiary">
				Profiles
			</Button>
		</NuxtLink>
		<NuxtLink :to="LoganStore.isSignedIn ? '/address-book' : ''" class="block">
			<Button size="lg" class="justify-start w-full" variant="tertiary">
				Address Book
			</Button>
		</NuxtLink>
		<NuxtLink v-if="storeType === 'OCTANE'" to="/orders" class="block">
			<Button size="lg" class="justify-start w-full" variant="tertiary">
				Orders
			</Button>
		</NuxtLink>
		<!-- <Button size="lg" class="justify-start" variant="tertiary">
			Rewards
		</Button> -->
	</div>
</template>
